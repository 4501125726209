<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog" v-if="month === (legacy ? current_month_legacy : current_month)">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="dense-wrapper mt-2">
          <v-col cols="12" md="6">
            <date-component monthly v-model="month" req label="Місяць" :class_="!month ? 'req-star' : ''"
                            disabled
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="create_date" req
                            label="Дата створення"
                            format-string="DD.MM.YYYY HH:mm:ss"
                            :class_="!create_date ? 'req-star' : ''"
                            disabled
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-select :items="services" hide-details filled
                      v-model="service_id" label="Послуга"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="!service_id ? 'req-star' : ''"
            />
          </v-col>
          <v-col cols="12">
            Відбір абонентів
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="filter_data.reading_months" label="К-сть міс. для показів"
                          filled :class="!filter_data.reading_months ? 'req-star' : ''"
                          hide-details required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="filter_data.max_result" label="Максимальне значення об’єму"
                          filled
                          hide-details
                          color="grey"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect v-model="filter_data.city_id"
                                  label="Населений пункт" filled
                                  select_type="city"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect :parent_id="filter_data.city_id" v-model="filter_data.streets"
                                  label="Вулиця(-ки)" multiple filled
                                  select_type="street" use_parent_id
                                  :disabled="!filter_data.city_id"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect :parent_id="filter_data.streets" v-model="filter_data.buildings"
                                  label="Будинок(-ки)" multiple filled
                                  select_type="building" use_parent_id
                                  :disabled="!filter_data.streets.length"
            />
          </v-col>
          <v-col cols="12">
            <Checker :value="filter_data.checker_id" @autocompleteChange="onCheckerChange"/>
          </v-col>
          <v-col cols="12">
            <v-select hide-details filled v-model="filter_data.building_type"
                      :items="buildingTypes_select" label="Вид житла"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-switch v-model="filter_data.all_counters_without_reading"
                      label="Не подані покази по усіх лічильниках"
                      filled
                      hide-details
                      color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept"
             :disabled="month !== (legacy ? current_month_legacy : current_month)"
      >
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_FLAT_COUNTER_AVG,
  CREATE_FLAT_COUNTER_AVG_LEGACY,
  UPDATE_FLAT_COUNTER_AVG,
  UPDATE_FLAT_COUNTER_AVG_LEGACY,
  DELETE_FLAT_COUNTER_AVG,
  DELETE_FLAT_COUNTER_AVG_LEGACY
} from "@/store/actions/flat_counter_avg";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {buildingTypes_select} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_counter_avg_modal_delete'
const filter_data = {
  city_id: null,
  streets: [],
  buildings: [],
  building_type: null,
  checker_id: null,
  reading_months: 6,
  all_counters_without_reading: true,
  max_result: null,
}

export default {
  name: "HWP_Modal_FlatCounterAvg",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker"),
  },
  data() {
    return {
      service_id: this.item.service_id || null,
      month: this.item.month || null,
      create_date: this.item.create_date || null,
      filter_data: Object.assign({}, this.item.filter_data || filter_data),
      legacy: this.item.legacy || false,
      buildingTypes_select
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.filter_data.checker_id = payload.value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.service_id = this.item.service_id || null
      this.month = this.item.month || null
      this.create_date = this.item.create_date || null
      this.legacy = this.item.legacy || false
      this.filter_data = Object.assign({}, this.item.filter_data || filter_data)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення списку показів по середньому',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Місяць, послуга, к-сть міс.для показів мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        month: this.month,
        service_id: this.service_id,
        filter_data: this.filter_data,
      }

      const ACTION_CREATE = this.legacy ? CREATE_FLAT_COUNTER_AVG_LEGACY : CREATE_FLAT_COUNTER_AVG
      const ACTION_UPDATE = this.legacy ? UPDATE_FLAT_COUNTER_AVG_LEGACY : UPDATE_FLAT_COUNTER_AVG

      if (this.isNew) {
        this.$store.dispatch(ACTION_CREATE, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(ACTION_UPDATE, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.month = payload.month || null
              this.service_id = payload.service_id || null
              this.create_date = payload.create_date || null
              this.filter_data = Object.assign({}, payload.filter_data || filter_data)
              this.legacy = payload.legacy || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  const ACTION_DELETE = this.legacy ? DELETE_FLAT_COUNTER_AVG_LEGACY : DELETE_FLAT_COUNTER_AVG

                  this.$store.dispatch(ACTION_DELETE, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithCounter',
      current_month: 'getCurrentMonth',
      current_month_legacy: 'getCurrentMonthLegacy',
    }),
  }
}
</script>
